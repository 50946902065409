import React, { useState, useEffect, useRef } from 'react';
import './normalize.css';
import './webflow.css';
import './tormo-account.webflow.css';
import './App.css';
import axios from 'axios';
import TORMO from './images/S__5570563-1.png';

function Otp({ email, onOtpVerification }: { email: string; onOtpVerification: () => void }) {
  // An array of state for each OTP input
  const [otp, setOtp] = useState(['', '', '', '']);
  // References for each input field to manage focus
  const otpRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  useEffect(() => {
    // Automatically focus on the first input field when the component loads
    otpRefs[0].current?.focus();
  }, []);

  useEffect(() => {
    // Automatically attempt to verify the OTP when all four fields are filled
    const otpCode = otp.join('');
    if (otpCode.length === 4) {
      verifyOtp(otpCode);
    }
  }, [otp]);

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);

    // Move focus to the next field once the current one is filled
    if (event.target.value && index < 3) {
      otpRefs[index + 1].current?.focus();
    }
  };

  const verifyOtp = async (otpCode: string) => {
    try {
      const response = await axios.post('https://otp-new.onrender.com/verify', { email, otp: otpCode });
      if (response.status === 200) {
        alert('OTP verification successful.');
        onOtpVerification(); // Ensure this matches the expected behavior in your App component
      } else {
        alert('OTP verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('OTP verification failed. Please try again.');
    }
  };

  const resendOtp = async () => {
    try {
      const response = await axios.post('https://otp-new.onrender.com/resendCode', { email });
      if (response.status === 200) {
        alert('OTP has been resent to your email.');
      } else {
        alert('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      alert('Failed to resend OTP. Please try again.');
    }
  };

  return (
    <div className="otp">
      <div className="header-login">
        <img src={TORMO} loading="lazy" alt="" className="image" />
      </div>
      <div className="div-password">
        <div className="div-password-inside">
          <h1 className="heading-2 votething">CODE</h1>
          <div className="text-block-2 votething">{email} にコードを送信しました。<a onClick={resendOtp}><span className="text-span">再送</span></a></div>
          <div className="form-block votething otp w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-2 otp" data-wf-page-id="65e828836d4d7190c5e862ba" data-wf-element-id="dc06f73b-04d2-d94c-6a07-b3e67433ba8a">
            {otp.map((value, index) => (
              <input
                key={index}
                ref={otpRefs[index]}
                className="text-field-2 email otp w-input"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(index, e)}
                type="text"
                autoComplete="off" // Helps prevent browsers from auto-filling these fields
              />
            ))}
          </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
