import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import './normalize.css';
import './webflow.css';
import './tormo-account.webflow.css';
import './App.css';
import axios from 'axios';
import TORMO from './images/S__5570563-1.png';

function Login({ onEmailSubmit }: { onEmailSubmit: (email: string) => void }) {
  const [email, setEmail] = useState('');

  const provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/ba50df76ec5643c9819bf54b6fedcc06");
  const contractAddress = ethers.utils.getAddress("0x905C50B510CC8bC68eA8a64A85Cf66cE90A67F33");
  const contractAbi = ['function metadata(string) public view returns (string)'];
  const contract = new ethers.Contract(contractAddress, contractAbi, provider);

  async function createModulus(email: string) {
    const hash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
    const portionOfHash = hash.substring(0, 16);
    const numericValue = parseInt(portionOfHash, 16);
    const modulusBase = 88888888;
    const modulus = numericValue % modulusBase;
    return modulus.toString();
  }

  async function getCondition(email: string) {
    try {
      const newEmail = email.toLowerCase();
      const modulus = await createModulus(newEmail);
      const condition = await contract.metadata(modulus);
      return condition !== "";
    } catch (error) {
      console.error("Error calling contract:", error);
      return false;
    }
  }

  const handleSubmit = async () => {
    try {

      //const 
      //const condition = await 
      const condition = await getCondition(email);
      if (!condition) {
        alert ('User not found');
        return
      }
      const response = await axios.post('https://otp-new.onrender.com/sendCode', { email });
      if (response.status === 200) {
        onEmailSubmit(email); // Use the provided callback to notify about successful submission
      } else {
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP. Please try again.');
    }
  };

  return (
    <div className="login">
      <div className="header-login">
        <img src={TORMO} loading="lazy" alt="" className="image" />
      </div>
      <div className="div-login">
        <div className="div-login-main">
          <h1 className="heading-title heading-title-login">Log in</h1>
          <div className="subtitle-login sub">メールアドレスを入力してください。</div>
          <div className="form-block votething w-form">
            {/* First Form for Email Input */}
            <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-email-take" data-wf-page-id="65e827bba5f0a9e60fa04676" data-wf-element-id="2ada8aaf-9bce-1566-9d69-ef36ba914941">
            <input
              className="form-text-input-email email w-input"
              maxLength={256}
              name="email"
              placeholder="user@example.com"
              type="email"
              id="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            </form>
          </div>
          <div className="form-block _4digits w-form">
            {/* Second Form for Submit Button */}
            <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-email-take" data-wf-page-id="65e827bba5f0a9e60fa04676" data-wf-element-id="2ada8aaf-9bce-1566-9d69-ef36ba91494a">
            <button 
              className="button vvv w-button" 
              onClick={handleSubmit} // Using onClick here to manually handle submission
              type="button" // Changed to type button to prevent default form submission
            >
              SUBMIT
            </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
