import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import './App.css';
import './normalize.css';
import './webflow.css';
import './tormo-account.webflow.css';
import './tormo-popup.webflow.css';
import TORMO from './images/S__5570563-1.png';
import WALLET from './images/wallet.png';
import LOGOUT from './images/logout.png';

interface NFT {
  contractAddress: string;
  tokenId: string;
  metadata: {
    imageUrl: string;
    description: string;
    name: string;
  };
  balance: string;
  chainId: string;
}

function Viewer({ walletAddress }: { walletAddress: string }) {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [selectedNftIndex, setSelectedNftIndex] = useState(0);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [targetAddress, setTargetAddress] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const [otp, setOtp] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);

  const specialNftAddress = "0x555447671264A056280F5cAd9bcD2198d3cf47b9";
  const specialVideoUrl = "https://ipfs.io/ipfs/QmRGyuNX8AWvpMPGC2yePuHCsgkku7YRRZjgMLA2GyS6Kb";

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const response = await axios.get(`https://nft-own-check.onrender.com/checkNFTs/${walletAddress}`);
        setNfts(response.data);
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      }
    };

    fetchNFTs();
  }, [walletAddress]);

  useEffect(() => {
    setIsValidAddress(ethers.utils.isAddress(targetAddress));
  }, [targetAddress]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => alert('Wallet address copied successfully!'))
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleTransfer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidAddress) {
      alert('Please enter a valid Ethereum address.');
      return;
    }

    // Example OTP send request
    try {

      const requestBodyEmail = {
        wallet_address: walletAddress
      };
        
      const requestEmail = await axios.post('https://api.tormo.dev/dev/fetchTormoWalletAddress', requestBodyEmail, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'DjNTil697a3xDILaRCYqh89a84DG1UhF9FbrzYZI'
        }
      });
        
      if (requestEmail.status === 200) {
        console.log('Successfull email data:', requestEmail.data);
      } else {
        console.error('Failed to get email data:', requestEmail.data);
        throw new Error('API returned an unsuccessful status');
      }
        
      const email = requestEmail.data.customer_email_address;
      
      const response = await axios.post('https://otp-new.onrender.com/sendCode', { email: email });
      if (response.status === 200) {
        setShowOtpModal(true);
      } else {
        alert('Failed to send OTP.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP.');
    }
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const verifyOtpAndTransfer = async () => {
    // Example OTP verification request
    setIsTransferring(true);

    const requestBodyEmail = {
        wallet_address: walletAddress
    };
        
    const requestEmail = await axios.post('https://api.tormo.dev/dev/fetchTormoWalletAddress', requestBodyEmail, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'DjNTil697a3xDILaRCYqh89a84DG1UhF9FbrzYZI'
        }
    });
        
    if (requestEmail.status === 200) {
      console.log('Successfull email data:', requestEmail.data);
    } else {
      console.error('Failed to get email data:', requestEmail.data);
      throw new Error('API returned an unsuccessful status');
    }
        
    const email = requestEmail.data.customer_email_address;

    try {
      const response = await axios.post('https://otp-new.onrender.com/verify', { email, otp });
      if (response.status === 200) {
        const transferData = {
          chainId: nfts[selectedNftIndex].chainId,
          tokenId: nfts[selectedNftIndex].tokenId,
          contractAddress: nfts[selectedNftIndex].contractAddress,
          targetAddress,
          userAddress: walletAddress,
        };
        const transferResponse = await axios.post('https://tormo-server-test.onrender.com/transfer', transferData);
        if (transferResponse.data.success) {
          alert('Transfer successful!');
        } else {
          alert('Transfer failed.');
        }
      } else {
        alert('Transfer or OTP verification failed.');
      }
    } catch (error) {
      console.error('Error during transfer or OTP verification:', error);
      alert('Transfer failed.');
    }
    setIsTransferring(false);
    setShowOtpModal(false);
  };

  const selectNft = (index: number) => {
    setSelectedNftIndex(index);
  };

  // Define nftTitle and nftDescription based on selected NFT
  const nftTitle = nfts.length > 0 ? nfts[selectedNftIndex].metadata.name : '';
  const nftDescription = nfts.length > 0 ? nfts[selectedNftIndex].metadata.description : '';

  return (
    <div className="body">
      <div className="w-layout-vflex flex-block">
        <div className="div-tormo-logo">
          <img src={TORMO} loading="lazy" width="104" alt="" className="tormo" />
          <a onClick={copyToClipboard} className="div-white">
            <img src={WALLET} loading="lazy" alt="" className="wallet" />
          </a>
        </div>
        <a href="https://viewer.tormo.online" className="div-white">
          <img src={LOGOUT} loading="lazy" alt="" className="wallet logout" />
        </a>
      </div>
      <div className="div-wrapper">
      <div className="div-detail nft">
          <section className="hero-subscribe-right">
            <div className="container">
              <div className="hero-wrapper">
                {nfts.length > 0 && (
                  <>
                    <div className="hero-split image">

                      {/* Check if the selected NFT's contractAddress is the special address */}
                      {nfts[selectedNftIndex].contractAddress === specialNftAddress ? (
                        <video controls className="shadow-two">
                          <source src={specialVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={nfts[selectedNftIndex].metadata.imageUrl} loading="lazy" alt="" className="shadow-two" />
                      )}

                    </div>
                    <div className="hero-split">
                      <h1 className="heading">{nftTitle}</h1>
                      <p className="margin-bottom-24px">{nftDescription}</p>

                      <div className="w-form">
                      <form onSubmit={handleTransfer} className="form">
                      <input
                      onChange={(e) => setTargetAddress(e.target.value)}
                      value={targetAddress}
                      className="text-field w-input"
                      maxLength={256}
                      name="targetAddress"
                      placeholder="0x0000000..."
                      type="text"
                      required
                      />
                      <input
                      type="submit"
                      value={isTransferring ? '...' : 'Transfer'}
                      disabled={!isValidAddress || isTransferring}
                      className={`submit-button-ye w-button ${!isValidAddress || isTransferring ? 'disabled-button' : ''} ${isTransferring ? 'loading-button' : ''}`}
                      />
                      </form>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className="div-detail gallery">
          <div className="div-block">
            <div className="text-block">Gallery</div>
          </div>
          <div className="container-nft-gallery">
            <section className="team-circles">
              <div className="container-nft">
                <div className="team-grid">
                  {nfts.map((nft, index) => (
                    <div
                      key={index}
                      className={`team-card ${selectedNftIndex === index ? '' : 'no'}`}
                      onClick={() => selectNft(index)}
                    >
                      <img src={nft.metadata.imageUrl} loading="lazy" alt={nft.metadata.name} className={`team-member-image ${selectedNftIndex === index ? '' : 'no'}`} />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>

        {showOtpModal && (
            <div>
            <div className="modal-wrapper">
              <div className="mdoal-backgound"></div>
              <div className="modal-card">
                <h1 className="heading-popup">CODE</h1>
                <div className="text-block-popup">Emailにコードを送信しました。</div>
                <div className="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-popup" data-wf-page-id="65e985e6fdbfecff0dde599a" data-wf-element-id="f56bcbcd-d70d-8981-1e54-7b4eed675684">
                    <input className="text-field-popup w-button" value={otp} onChange={handleOtpChange} maxLength={4} name="name" data-name="Name" placeholder="" type="text" id="name" />
                    <input onClick={verifyOtpAndTransfer} disabled={isTransferring} type="submit" data-wait="Please wait..." className="submit-button-popup w-button" value="Submit" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default Viewer;
