import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import Login from './Login';
import Otp from './Otp';
import Viewer from './Viewer';

function App() {
  const [currentStage, setCurrentStage] = useState('login');
  const [email, setEmail] = useState('');
  const [walletAddress, setWalletAddress] = useState('');

  // Function to fetch the wallet address
  const fetchWalletAddress = async (email: string) => {
    try {
      const response = await axios.post('https://api.tormo.dev/dev/fetchTormoCustomerEmailAddress', {
        customer_email_address: email,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'DjNTil697a3xDILaRCYqh89a84DG1UhF9FbrzYZI'
        }
      });
      if (response.data && response.data.wallet_address) {
        setWalletAddress(response.data.wallet_address);
        setCurrentStage('viewer');
      } else {
        alert('Failed to retrieve wallet address. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching wallet address:', error);
      alert('Failed to fetch wallet address. Please try again.');
    }    
  };

  useEffect(() => {
    // Check for direct navigation with a wallet address in the path
    const path = window.location.pathname.split('/');
    if (path[1] === 'viewer' && path[2]) {
      setWalletAddress(path[2]);
      setCurrentStage('viewer');
    }
  }, []);

  const handleEmailSubmit = (email: string) => {
    setEmail(email);
    setCurrentStage('otp');
  };

  const handleOtpVerification = () => {
    // Fetch and set wallet address after OTP verification
    fetchWalletAddress(email);
  };

  switch (currentStage) {
    case 'login':
      return <Login onEmailSubmit={handleEmailSubmit} />;
    case 'otp':
      return <Otp email={email} onOtpVerification={handleOtpVerification} />;
    case 'viewer':
      // Directly pass the walletAddress to the Viewer component
      return <Viewer walletAddress={walletAddress} />;
    default:
      return <div>Invalid stage</div>;
  }
}

export default App;
